<template>
  <div id="settings">
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent="submit"
      autocomplete="off"
    >
      <div
        class="container-fluid pt-4 pd-content"
        v-if="modes && modes.length > 0"
      >
        <v-row class="page-title row pt-4" no-gutters
          ><span>Dimming Settings</span>
        </v-row>
        <div class="row m-0">
          <div class="col-md-6 col-2-custom mb-pb-0" id="show-court">
            <div class="row mb-pb-0 height-100">
              <div class="col-12 mb-pb-0">
                <div class="session-mode-inside">
                  <h3 class="m-0 pb-3 title-heading">INDOOR SHOW COURT</h3>
                  <div class="row">
                    <div
                      v-for="(item, index) in modes"
                      :key="index"
                      class="col-12 pb-0"
                      :class="item.group == 'Indoor Show Court' ? '' : 'd-none'"
                    >
                      <div v-if="item.group == 'Indoor Show Court'">
                        <label>{{ item.name }} - Dimming</label>
                        <v-text-field
                          type="number"
                          required
                          filled
                          outlined
                          step="1"
                          :rules="inputModeRules"
                          class="input-setting-mode"
                          v-model="item.dimming"
                          :validate-on-blur="true"
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-2-custom mb-pb-0" id="practice-court">
            <div class="row mb-pb-0 height-100">
              <div class="col-12 mb-pb-0">
                <div class="session-mode-inside">
                  <h3 class="m-0 pb-3 title-heading">INDOOR PRACTICE COURT</h3>
                  <div class="row">
                    <div
                      v-for="(item, index) in modes"
                      :key="index"
                      class="col-12 pb-0"
                      :class="
                        item.group == 'Indoor Practice Court' ? '' : 'd-none'
                      "
                    >
                      <div v-if="item.group == 'Indoor Practice Court'">
                        <label>{{ item.name }} - Dimming</label>
                        <v-text-field
                          type="number"
                          required
                          filled
                          outlined
                          step="1"
                          :rules="inputModeRules"
                          class="input-setting-mode"
                          v-model="item.dimming"
                          :validate-on-blur="true"
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-2-custom mb-pb-0" id="tennis-court">
            <div class="row mb-pb-0 height-100">
              <div class="col-12 mb-pb-0">
                <div class="session-mode-inside">
                  <h3 class="m-0 pb-3 title-heading">OUTDOOR TENNIS COURT</h3>
                  <div class="row">
                    <div
                      v-for="(item, index) in modes"
                      :key="index"
                      class="col-12 pb-0"
                      :class="
                        item.group == 'Outdoor Tennis Court' ? '' : 'd-none'
                      "
                    >
                      <div v-if="item.group == 'Outdoor Tennis Court'">
                        <label>{{ item.name }} - Dimming</label>
                        <v-text-field
                          type="number"
                          required
                          filled
                          outlined
                          step="1"
                          :rules="inputModeRules"
                          class="input-setting-mode"
                          v-model="item.dimming"
                          :validate-on-blur="true"
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-2-custom mb-pb-0" id="junior-court">
            <div class="row mb-pb-0 height-100">
              <div class="col-12 mb-pb-0">
                <div class="session-mode-inside">
                  <h3 class="m-0 pb-3 title-heading">JUNIOR COURT</h3>
                  <div class="row">
                    <div
                      v-for="(item, index) in modes"
                      :key="index"
                      class="col-12 pb-0"
                      :class="item.group == 'Junior Court' ? '' : 'd-none'"
                    >
                      <div v-if="item.group == 'Junior Court'">
                        <label>{{ item.name }} - Dimming</label>
                        <v-text-field
                          type="number"
                          required
                          filled
                          outlined
                          step="1"
                          :rules="inputModeRules"
                          class="input-setting-mode"
                          v-model="item.dimming"
                          :validate-on-blur="true"
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-2-custom mb-pb-0" id="rooftop-court">
            <div class="row mb-pb-0 height-100">
              <div class="col-12 mb-pb-0">
                <div class="session-mode-inside">
                  <h3 class="m-0 pb-3 title-heading">ROOFTOP COURT</h3>
                  <div class="row">
                    <div
                      v-for="(item, index) in modes"
                      :key="index"
                      class="col-12 pb-0"
                      :class="item.group == 'Rooftop Court' ? '' : 'd-none'"
                    >
                      <div v-if="item.group == 'Rooftop Court'">
                        <label>{{ item.name }} - Dimming</label>
                        <v-text-field
                          type="number"
                          required
                          filled
                          outlined
                          step="1"
                          :rules="inputModeRules"
                          class="input-setting-mode"
                          v-model="item.dimming"
                          :validate-on-blur="true"
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 pt-2">
          <v-col cols="12" md="12" class="text-right">
            <button type="submit" class="submit-button">Save</button>
          </v-col>
        </div>
      </div>
    </v-form>
    <!-- Message popup -->
    <v-dialog
      v-model="messageDialog"
      max-width="700"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" style="padding-top: 6px">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12 pt-4" class="text-right">
              <button
                class="ml-4 btn btn-primary btn-custom"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <!-- End Message popup -->
  </div>
</template>

<style lang="scss" src="@/assets/css/settings.scss"></style>
<script>
import { courtService } from "@/services";
import modes_data from "@/components/modes_data";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      groupModes: {},
      modes: modes_data,
      valid: false,
      inputModeRules: [
        (v) => !!v || "Dimming is required",
        (v) => (!!v && v >= 0) || "The min value dimming is 0",
        (v) => (!!v && v <= 100) || "The max value dimming is 100",
      ],
    };
  },
  async created() {
    await this.getListModes();
  },
  methods: {
    async getListModes() {
      await courtService
        .getListModes()
        .then((res) => {
          if (res.status == 200 && res.data.success) {
            this.modes = res.data.data;
          } else throw res;
        })
        .catch((err) => {
          this.apiMessage = "Get list modes failed.";
          if (err?.response?.data?.message) {
            this.apiMessage = err?.response?.data?.message;
          }
          this.showMessage("Error");
        });
    },
    checkDataInvalid() {
      let messages = [];
      for (let mode of this.modes) {
        if (!mode.dimming) {
          messages.push(mode.group + " " + mode.name + " dimming is required.");
        } else if (mode.dimming == "e") {
          messages.push(mode.group + " " + mode.name + " dimming is invalid.");
        }
      }
      if (messages.length > 0) {
        this.apiMessage = messages.join("<br>");
        this.showMessage("Error");
        return false;
      }
      return true;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.checkDataInvalid()) {
          let _modes = this.modes.map((item) => {
            return { id: item.id, dimming: parseInt(item.dimming) };
          });
          await courtService
            .updateDimmingMode({ modes: _modes })
            .then((res) => {
              if (res.status == 200 && res.data.success) {
                this.apiMessage = res.data.message;
                this.showMessage("Success");
                this.getListModes();
              } else throw res;
            })
            .catch((err) => {
              this.apiMessage = "Update dimming modes failed.";
              if (err?.response?.data?.message) {
                this.apiMessage = err?.response?.data?.message;
              }
              this.showMessage("Error");
            });
        }
      }
    },
    showMessage(apiTitle) {
      this.apiTitle = apiTitle;
      this.messageDialog = true;
    },
    closeMessage() {
      this.messageDialog = false;
    },
  },
};
</script>
